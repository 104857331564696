<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;">线上考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back();">试卷列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">选择试卷</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">试卷名称:</span>
              <el-input v-model="retrievalData.paperName" type="text" size="small" placeholder="请输入试卷名称" clearable />
            </div>
            <div title="试卷类型" class="searchboxItem ci-full">
              <span class="itemLabel">试卷类型:</span>
              <el-select v-model="retrievalData.examType" filterable clearable size="small" placeholder="请选择试卷类型">
                <el-option v-for="item in examTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="determineTestPaper">确定</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe @selection-change="handleSelectionChange"
              :row-key="(row) => row.paperId">
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <!-- <el-table-column label="序号" align="center" type="index" :index="indexMethod" /> -->
              <el-table-column label="试卷名称" align="left" show-overflow-tooltip prop="paperName" minWidth="200" />
              <el-table-column label="试卷类型" align="left" show-overflow-tooltip prop="examType" minWidth="100">
                <template slot-scope="scope">
                  {{ $setDictionary("TOOL_EXAM_TYPE", scope.row.examType) }}
                </template>
              </el-table-column>
              <el-table-column label="试题数量" align="left" show-overflow-tooltip prop="questionNum" minWidth="100" />
              <el-table-column label="试题总分" align="left" show-overflow-tooltip prop="score" minWidth="100" />
              <el-table-column label="创建时间" align="left" show-overflow-tooltip min-width="150px">
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="preview(scope.row)">预览</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
        <!-- <div class="BatchBox">
          <div class="BatchBox_1">注意：选择多条试卷时，学员进行考试答题时将随机分配一套试卷；</div>
          <div class="BatchBox_2"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examination_onlineTest_queryExaminationPaperList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        examinationId: "", // 考试id
        score: "", // 试题总分
      },
      // 检索数据
      retrievalData: {
        paperName: "", // 试卷名称
        examType: "", // 试卷类型
      },
      // 试卷类型 - 下拉数据
      examTypeList: [],
      // 列表已勾选数据
      multipleSelection: [],
    };
  },
  created() {
    this.lastPageData.examinationId = this.$route.query.examinationId;
    this.lastPageData.score = this.$route.query.score;
    this.dataDictionary();
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 数据字典
    dataDictionary() {
      // 试卷类型
      const examTypeList = this.$setDictionary(
        "TOOL_EXAM_TYPE",
        "list"
      );
      const list1 = [];
      for (const key in examTypeList) {
        list1.push({
          value: key,
          label: examTypeList[key],
        });
      }
      this.examTypeList = list1;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        examinationId: this.$route.query.examinationId,
      };
      if (this.retrievalData.paperName) {
        params.paperName = this.retrievalData.paperName;
      }
      if (this.retrievalData.examType) {
        params.examType = this.retrievalData.examType;
      }
      this.doFetch({
        url: "/biz/examination/paper/selectChoiceList",
        params,
        pageNum,
      }, true, 6);
    },
    // 列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 确定
    determineTestPaper() {
      if (!this.multipleSelection.length) {
        this.$message({
          type: "warning",
          message: '请选择试卷',
        });
        return
      }
      if (this.lastPageData.score && Number(this.lastPageData.score) <= 0) {
        this.$message({
          type: "warning",
          message: '已选历史试卷总分数为零，请仔细查看已选的历史试卷',
        });
        return
      }
      for (let i = 0; i < this.multipleSelection.length; i++) {
        if (!this.multipleSelection[i].score) {
          this.$message({
            type: "warning",
            message: '试卷总分数不能为0或没有分数',
          });
          return
        }
        if (Number(this.multipleSelection[i].score) != Number(this.lastPageData.score)&&this.lastPageData.score) {
          this.$message({
            type: "warning",
            message: '选择的试卷和之前的试卷总分不一致',
          });
          return
        }
      }
      if (new Set(this.multipleSelection.map(item => item.score)).size > 1) {
        this.$message({
          type: "warning",
          message: '选择的试卷存在总分不一致',
        });
        return
      }

      let paperIds = [];
      for(let i = 0; i < this.multipleSelection.length; i++){
        paperIds.push(this.multipleSelection[i].paperId)
      }
      let cData = {
        examinationId: this.lastPageData.examinationId,
        paperIds: paperIds
      }
      this.$post("/biz/examination/paper/addPaper", cData, 3000, true, 6)
        .then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.$router.back();
        })
        .catch((err) => {
          return;
        });
    },
    // 预览
    preview(row) {
      this.$router.push({
        path: "/inkQuestionBank/examinationPaperManage/tacticsUpdate",
        query: {
          paperId: row.paperId,
          stu: 'view'
        }
      });
    }
  },
};
</script>
<style lang="less" scoped>
.BatchBox {
  overflow: hidden;
  .BatchBox_1 {
    line-height: 30px;
  }
  .BatchBox_2 {
    border: 1px solid #ebeef5;
    border-radius: 5px;
    height: 100px;
  }
  .BatchBoxFirst {
    width: 260px;
    background: #f2f7fd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .BatchBoxSecond {
    width: 100%;

    .BatchforBox {
      display: flex;
      flex-wrap: wrap;

      .BatchforFlex {
        display: flex;
        align-items: center;
        padding: 5px 20px;
        cursor: pointer;

        .BatchImgBox {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}</style>
  